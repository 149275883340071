import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  CookieLayer,
  Navigation,
  NavigationProvider,
  NavigationContext,
  MainContainer,
  Footer
} from '@ccg/qatar';

import { getNextLinkProps } from '../../../helper';

import Seo from '../../shared/Seo/Seo';

const PageTemplate = ({ cookieLayer, navigation, footer, social, seo, seoDefaults, children }) => {
  const { hiddenNavigationElements } = useContext(NavigationContext);
  return (
    <>
      <div className="transition__curtain" />
      <Seo data={seo} defaults={seoDefaults} />

      <div className="transition__content">
        {navigation && navigation.mainLinks.length > 0 && (
          <Navigation
            homeLink={{ href: '/', asPath: '/' }}
            mainLinks={navigation.mainLinks.map(({ linkTo, subLinks, id }) => ({
              id,
              ...getNextLinkProps(linkTo),
              subLinks: subLinks.map(link => getNextLinkProps(link.linkTo))
            }))}
          />
        )}
        <MainContainer>
          {children}
          {!hiddenNavigationElements && (
            <Footer
              homeLink={{ href: '/', asPath: '/' }}
              mainLinks={
                footer &&
                footer.mainLinks &&
                footer.mainLinks.map(link => getNextLinkProps(link.linkTo))
              }
              links={
                social &&
                social.socialLinks &&
                social.socialLinks.map(({ linkTo, socialService }) => ({
                  socialService,
                  ...getNextLinkProps(linkTo)
                }))
              }
            />
          )}
        </MainContainer>
        {cookieLayer && <CookieLayer {...cookieLayer} />}
      </div>
    </>
  );
};

PageTemplate.propTypes = {
  navigation: PropTypes.shape().isRequired,
  footer: PropTypes.shape().isRequired,
  social: PropTypes.shape(),
  seo: PropTypes.shape().isRequired,
  seoDefaults: PropTypes.shape().isRequired,
  cookieLayer: PropTypes.shape().isRequired,
  children: PropTypes.node
};

PageTemplate.defaultProps = { children: null, social: null };

// eslint-disable-next-line react/display-name
export default ({ ...props }) => (
  <NavigationProvider>
    <PageTemplate {...props} />
  </NavigationProvider>
);
